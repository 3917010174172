//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiArrowRight } from "@mdi/js";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  return {
    mdiArrowRight
  };
};

export default __sfc_main;
